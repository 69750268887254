/* ========== porfolio-designers ========== */
.portfolio-designers {
	padding: 64px 0 84px;
	&__text {
		color: #572d56;
		font-size: 14px;
		font-weight: 400;
		line-height: lh(20, 14);
	}
	&__title {
		margin: 50px 0 58px;
		color: #572d56;
		font-size: 27px;
		line-height: lh(33, 27);
		text-transform: uppercase;
		font-weight: 400;
		b {
			font-weight: 700;
		}
	}
}
.portfolio-gallery {
	margin-bottom: 78px;
	&__container {
		overflow: hidden;
	}
	&__row {
		font-size: 0;
		margin: 0 -4px;
	}
	&__col {
		display: inline-block;
		vertical-align: top;
		width: 33.3%;
		padding: 0 4px;
	}
	&__col &__cell {
		display: block;
		width: auto;
	}
	&__col &__cell:first-child {
		margin-bottom: 8px;
	}
	&__col-x2 {
		display: inline-block;
		vertical-align: top;
		width: 66.6%;
		padding: 0 4px;
	}
	&__col-x2 &__col {
		width: 50%;
	}
	&__col-x2 &__col:nth-child(1) {
		padding: 0 4px 0 0;
	}
	&__col-x2 &__col:nth-child(2) {
		padding: 0 0 0 4px;
	}
	&__col-x2 &__col_horizontal {
		display: block;
		width: auto;
		padding: 0;
	}
	&__cell {
		position: relative;
	}
	&__img {
		img {
			display: block;
			width: 100%;
		}
	}
	&__overlay {
		opacity: 0;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		text-align: center;
		background-color: #4f254d;
		background-color: rgba(79, 37, 77, .8);
		z-index: 1;
		transition: opacity .15s;
	}
	&__info {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	&__caption {
		color: #fff;
		font-size: 16px;
		line-height: 20px;
		font-weight: 400;
		margin-bottom: 20px;
		strong {
			display: block;
			font-weight: 700;
		}
	}
	&__btn {
		display: inline-block;
		height: 48px;
		padding: 0 20px;
		border: 2px solid #fff;
		border-radius: 24px;
		color: #fff;
		font-size: 16px;
		font-weight: 700;
		line-height: 48px;
		text-transform: uppercase;
		text-decoration: none;
		background-color: transparent;
		transition: background-color .15s;
		&:hover {
			background-color: #4f254d;
		}
	}
	&__link {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		color: #fff;
		font-size: 24px;
		line-height: lh(28, 24);
		font-weight: 400;
		text-decoration: none;
		span {
			display: block;
			margin-bottom: 20px;
		}
		b {
			font-weight: 700;
		}
	}
	&__link-text {
		position: absolute;
		z-index: 1;
		left: 0;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
	}
	&__cell:hover {
		.portfolio-gallery__overlay {
			opacity: 1;
		}
	}
}
.blocker {
	z-index: 20;
}
.modal-portfolio {
	position: relative;
	vertical-align: middle;
	width: 80%;
	max-width: 1100px;
	padding: 60px 45px 70px;
	background-color: #fff;
	outline: 0;
	a.close-modal {
		position: absolute;
		right: 0;
		top: 20px;
		width: 45px;
		height: 45px;
		background: #572d56 url($img + '/gallery-designers/modal-close.png') no-repeat 50%;
	}
	&__head {
		margin-bottom: 24px;
		padding-left: 18px;
		border-left: 3px solid #572d56;
		text-align: left;
		color: #572d56;
	}
	&__title {
		display: block;
		margin-bottom: 16px;
		font-weight: 700;
		line-height: 1;
		text-transform: uppercase;
		font-size: 27px;
	}
	&__size {
		display: block;
		font-size: 14px;
	}
	&__content {
		display: table;
		width: 100%;
		table-layout: fixed;
		img {
			display: block;
			width: 100%;
		}
	}
	&__slider {
		display: table-cell;
		vertical-align: top;
		width: 60%;
	}
	&__info {
		position: relative;
		display: table-cell;
		vertical-align: top;
		width: 40%;
		padding: 0 0 220px 30px;
		color: #572d56;
		text-align: left;
	}
	&__desc {
		margin-bottom: 26px;
	}
	&__desc-title {
		display: block;
		font-size: 18px;
		font-weight: 700;
		line-height: 1;
		text-transform: uppercase;
	}
	&__desc-text {
		font-size: 16px;
		font-weight: 400;
		line-height: lh(20, 16);
	}
	&__callback {
		position: absolute;
		bottom: 0;
		right: 0;
		left: 30px;
	}
	&__callback-title {
		display: block;
		margin-bottom: 12px;
		font-size: 27px;
		font-weight: 700;
		line-height: 1;
		text-transform: uppercase;
	}
	&__callback-text {
		margin: 0 0 16px;
		font-size: 16px;
		font-weight: 400;
		line-height: lh(20, 16);
	}
	&__group {
		margin-bottom: 8px;
	}
	&__group_submit {
		margin-bottom: 0;
	}
	&__input {
		width: 251px;
		height: 48px;
		padding: 16px 25px;
		line-height: 1;
		border: 1px solid #572d56;
		border-radius: 24px;
		font-size: 14px;
		font-weight: 400;
		text-transform: uppercase;
	}
	&__input::-webkit-input-placeholder {
		color: #572d56;
	}
	&__input::-moz-placeholder {
		color: #572d56;
	}
	&__input:-moz-placeholder {
		color: #572d56;
	}
	&__input:-ms-input-placeholder {
		color: #572d56;
	}
	&__input[placeholder] {
		color: #572d56;
	}
	&__submit {
		display: inline-block;
		vertical-align: top;
		height: 48px;
		line-height: 48px;
		background-color: #572d56;
		border-radius: 24px;
		border: 0;
		padding: 0 32px;
		outline: 0;
		color: #fff;
		font-size: 16px;
		font-weight: 400;
		text-transform: uppercase;
		transition: opacity .15s;
		&:hover {
			opacity: .8;
		}
	}
}
.modal-slider {
	margin-bottom: 20px;
	.slick-arrow {
		width: 32px;
		height: 60px;
		z-index: 1;
		transition: opacity .15s;
		&:hover {
			opacity: .8;
		}
	}
	.slick-prev {
		left: 0;
		background: #572d56 url($img + '/gallery-designers/prev.png') no-repeat 50%;
	}
	.slick-next {
		right: 0;
		background: #572d56 url($img + '/gallery-designers/next.png') no-repeat 50%;
	}
	.slick-disabled,
	.slick-disabled:hover {
		cursor: default;
		opacity: .2;
	}
}
.modal-thumbs {
	.slick-list {
		margin: 0 -2px;
	}
	img {
		width: 100%;
	}
	&__item {
		position: relative;
		padding: 0 2px;
		cursor: pointer;
		outline: 0;
		&:after {
			content: "";
			position: absolute;
			top: 0;
			right: 2px;
			bottom: 0;
			left: 2px;
			background-color: rgba(79, 37, 77, .6);
			opacity: 0;
			transition: opacity .15s;
		}
		&:hover:after,
		&.slick-current:after {
			opacity: 1;
		}
		&.slick-current:hover {
			cursor: default;
		}
	}
}
.portfolio-designers__features {
	display: table;
	width: 100%;
	table-layout: fixed;
	margin-top: 70px;
}
.portfolio-designers__features-item {
	display: table-cell;
	font-size: 0;
	&:nth-child(1) {
		width: 34%;
		padding-right: 2%;
	}
	&:nth-child(2) {
		width: 34%;
		padding: 0 2%;
		border-left: 1px solid #dcd3db;
		border-right: 1px solid #dcd3db;
	}
	&:nth-child(3) {
		width: 32%;
		padding-left: 2%;
	}
}
.portfolio-designers__features-img {
	display: inline-block;
	vertical-align: middle;
	width: 26%;
	img {
		display: block;
		max-width: 100%;
	}
}
.portfolio-designers__features-text {
	display: inline-block;
	vertical-align: middle;
	width: 71%;
	margin: 0 0 0 3%;
	color: #572d56;
	font-size: 14px;
	font-weight: 400;
	line-height: lh(18, 14);
}
.our-team {
	padding: 74px 0 60px;
	color: #fff;
	background-color: #4f254d;
	&__title {
		display: block;
		text-align: center;
		font-size: 27px;
		margin-bottom: 50px;
		line-height: 1;
		text-transform: uppercase;
		font-weight: 400;
		b {
			font-weight: 700;
		}
	}
	&__members {
		position: relative;
		font-size: 0;
		padding-bottom: 56px;
		margin-bottom: 60px;
		&:after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 50%;
			width: 150px;
			height: 1px;
			margin-left: -75px;
			background-color: #623d61;
		}
	}
	&__member {
		display: inline-block;
		vertical-align: top;
		width: 25%;
		text-align: center;
	}
	&__photo {
		display: block;
		position: absolute;
		border-radius: 50%;
		top: 10px;
		left: -6px;
	}
	&__img {
		position: relative;
		border-radius: 50%;
		width: 181px;
		height: 181px;
		margin: 0 auto 20px;
		border: 2px solid #613b5f;
	}
	&__info {
		position: relative;
		left: -10px;
		display: inline-block;
		margin: auto;
		padding-left: 14px;
		border-left: 3px solid #725171;
		font-size: 14px;
		text-align: left;
	}
	&__name {
		display: block;
		font-weight: 700;
		line-height: 1;
		text-transform: uppercase;
	}
	&__post {
		display: block;
		font-weight: 400;
		line-height: lh(18, 14);
	}
}
.project-3d {
	max-width: 960px;
	margin: auto;
	font-size: 0;
	color: #fff;
	&__img {
		display: inline-block;
		vertical-align: middle;
		width: 30%;
	}
	&__wrap-img {
		position: relative;
		width: 236px;
		height: 236px;
		border-radius: 50%;
		border: 2px solid #613b5f;
		img {
			position: absolute;
			top: 0;
			left: 20px;
			border-radius: 50%;
		}
	}
	&__container {
		display: inline-block;
		vertical-align: top;
		width: 70%;
	}
	&__title {
		margin: 0 0 4px;
		line-height: 1;
		font-size: 27px;
		font-weight: 700;
		text-transform: uppercase;
	}
	&__subtitle {
		display: block;
		font-size: 20px;
		font-weight: 400;
		margin-bottom: 22px;
	}
	&__group {
		margin-bottom: 12px;
	}
	&__group_col {
		display: inline-block;
		vertical-align: top;
		width: 50%;
		&:first-child {
			width: 46%;
			margin-right: 4%;
		}
	}
	&__input {
		width: 100%;
		padding: 15px 20px;
		background-color: #583056;
		color: #fff;
		border-radius: 24px;
		font-size: 14px;
		font-weight: 400;
		line-height: 1px;
		text-transform: uppercase;
		border: 1px solid transparent;
		transition: border-color .15s;
		&:focus {
			border-color: #fff;
		}
	}
	&__input::-webkit-input-placeholder {
		color: #fff;
	}
	&__input::-moz-placeholder {
		color: #fff;
	}
	&__input:-moz-placeholder {
		color: #fff;
	}
	&__input:-ms-input-placeholder {
		color: #fff;
	}
	&__input[placeholder] {
		color: #fff;
	}
	&__submit {
		min-width: 214px;
		height: 48px;
		background-color: #fff;
		border-radius: 24px;
		border: 0;
		color: #572d56;
		font-size: 20px;
		font-weight: 700;
		line-height: 48px;
		text-transform: uppercase;
		transition: opacity .15s;
		&:hover {
			opacity: .8;
		}
	}
}
.text-block {
	padding: 50px 0 16px;
	font-size: 14px;
	font-weight: 400;
	line-height: lh(20, 14);
	color: #836b82;
	background-color: #572d56;
	ul {
		margin-left: 20px;
		li {
			position: relative;
			padding-left: 20px;
			&:before {
				content: "";
				position: absolute;
				top: 7px;
				left: 0;
				width: 5px;
				height: 5px;
				border-radius: 50%;
				background-color: #7c5b7b;
			}
		}
	}
}
@media (min-width: 480px) {
	.portfolio-gallery {
		&__col_horizontal &__info {
			top: 50%;
			left: 0;
			right: 0;
			transform: translateY(-50%);
		}
		&__col_horizontal &__caption,
		&__col_horizontal &__btn {
			display: inline-block;
			vertical-align: middle;
		}
		&__col_horizontal &__caption {
			margin: 0 30% 0 0;
		}
	}
}
@media (max-width: 1160px) {
	.modal-portfolio {
		width: 90%;
	}
}
@media (max-width: 1000px) {
	.modal-portfolio {
		width: 98%;
	}
}
@media (max-width: 960px) {
	.project-3d {
		&__img {
			display: none;
		}
		&__container {
			display: block;
			width: auto;
		}
	}
}
@media (max-width: 940px) {
	.modal-portfolio {
		max-width: 640px;
		&__content {
			display: block;
		}
		&__slider {
			display: block;
			width: auto;
			margin-bottom: 30px;
		}
		&__info {
			display: block;
			width: auto;
			padding: 0;
		}
		&__callback {
			position: static;
		}
	}
}
@media (max-width: 820px) {
	.our-team__member {
		width: 50%;
		margin-bottom: 40px;
	}
}
@media (max-width: 767px) {
	.portfolio-gallery {
		&__col-x2,
		&__col-x2 &__col,
		&__col {
			display: block;
			width: auto;
			padding: 0;
			margin-bottom: 8px;
		}
	}
	.portfolio-designers__features-img {
		display: block;
		width: auto;
		margin-bottom: 20px;
		img {
			margin: auto;
		}
	}
	.portfolio-designers__features-text {
		display: block;
		width: auto;
		margin: 0;
	}
}
@media (max-width: 520px) {
	.portfolio-gallery {
		margin-bottom: 40px;
	}
	.portfolio-designers__features {
		display: block;
		margin-top: 40px;
	}
	.portfolio-designers__features-item {
		display: block;
		&:nth-child(1) {
			width: 100%;
			padding-right: 0;
		}
		&:nth-child(2) {
			width: 100%;
			border-left: 0;
			border-right: 0;
			border-top: 1px solid #dcd3db;
			border-bottom: 1px solid #dcd3db;
			padding: 20px 0;
			margin: 20px 0;
		}
		&:nth-child(3) {
			width: 100%;
			padding-left: 0;
		}
	}
}
@media (max-width: 480px) {
	.modal-portfolio {
		padding: 30px 15px;
	}
	.project-3d {
		&__group_col {
			display: block;
			width: auto;
			&:first-child {
				width: auto;
				margin-right: 0;
			}
		}
		&__submit {
			width: 100%;
		}
	}
}
@media (max-width: 460px) {
	.our-team__member {
		display: block;
		width: auto;
	}
}
