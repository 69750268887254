@function decimal-round ($number, $digits: 0, $mode: round) {
    $n: 1;
    // $number must be a number
    @if type-of($number) != number {
        @warn '#{ $number } is not a number.';
        @return $number;
    }
    // $digits must be a unitless number
    @if type-of($digits) != number {
        @warn '#{ $digits } is not a number.';
        @return $number;
    } @else if not unitless($digits) {
        @warn '#{ $digits } has a unit.';
        @return $number;
    }
    @for $i from 1 through $digits {
        $n: $n * 10;
    }
    @if $mode == round {
        @return round($number * $n) / $n;
    } @else if $mode == ceil {
        @return ceil($number * $n) / $n;
    } @else if $mode == floor {
        @return floor($number * $n) / $n;
    } @else {
        @warn '#{ $mode } is undefined keyword.';
        @return $number;
    }
}

// Ceil a number to specified digits.
//
// @param  {Number} $number A number to round
// @param  {Number} [$digits:0] Digits to output
// @return {Number} A ceiled number
// @example
//     decimal-ceil(0.333)    => 1
//     decimal-ceil(0.333, 1) => 0.4
//     decimal-ceil(0.333, 2) => 0.34
//     decimal-ceil(0.666)    => 1
//     decimal-ceil(0.666, 1) => 0.7
//     decimal-ceil(0.666, 2) => 0.67
//
@function decimal-ceil ($number, $digits: 0) {
    @return decimal-round($number, $digits, ceil);
}

// Floor a number to specified digits.
//
// @param  {Number} $number A number to round
// @param  {Number} [$digits:0] Digits to output
// @return {Number} A floored number
// @example
//     decimal-floor(0.333)    => 0
//     decimal-floor(0.333, 1) => 0.3
//     decimal-floor(0.333, 2) => 0.33
//     decimal-floor(0.666)    => 0
//     decimal-floor(0.666, 1) => 0.6
//     decimal-floor(0.666, 2) => 0.66
//
@function decimal-floor ($number, $digits: 0) {
    @return decimal-round($number, $digits, floor);
}
//@mixin cf {
//	&:after {
//		content: "";
//		display: table;
//		clear: both;
//	}
//}
@mixin cf($extend: true) {
	@if $extend {
		@extend %clearfix;
	} @else {
		&:after {
			content: "";
			display: table;
			line-height: 0;
			clear: both;
		}
	}
}
%clearfix {
	@include cf($extend: false);
}

@mixin bs {
	html {
		box-sizing: border-box;
		*, *:before, *:after {
			box-sizing: inherit;
		}
	}
}
//полный перенос слов: необходимо только: <html lang="ru"></html>
@mixin word-wrap {
	-ms-word-break: break-all;
	word-break: break-all;
	-webkit-hyphens: auto;
	-moz-hyphens: auto;
	hyphens: auto;
}

@mixin placeholder {
	input[placeholder] {
		text-overflow: ellipsis;//Заменяем длинный текст placeholder многоточием:
		color: #000;
		//opacity: .45;
	}
	input::-webkit-input-placeholder {
		text-overflow: ellipsis;
		color: #000;
		//opacity: .45;
	}
	input::-moz-placeholder {
		text-overflow: ellipsis;
	}
	input:-moz-placeholder {
		text-overflow: ellipsis;
		color: #000;
		//opacity: .45;
	}
	input:-ms-input-placeholder {
		text-overflow: ellipsis;
		color: #000;
		//opacity: .45;
	}
	//Скрываем placeholder при фокусе:

	:focus::-webkit-input-placeholder {
		color: transparent !important;
	}
	:focus::-moz-placeholder {
		color: transparent;
	}
	:focus:-moz-placeholder {
		color: transparent;
	}
	:focus:-ms-input-placeholder {
		color: transparent;
	}
}

//*
//## Triangle
//* @include triangle within a pseudo element and add positioning properties (ie. top, left)
//* $direction: top, bottom, left, right
//*/
@mixin triangle($direction, $size: 8px, $color: #222){
	content: "";
	display: block;
	position: absolute;
	height: 0; width: 0;
	@if ($direction == "top"){
		border-bottom: $size solid $color;
		border-left: $size solid transparent;
		border-right: $size solid transparent;
	}
	@else if ($direction == "bottom"){
		border-top: $size solid $color;
		border-left: $size solid transparent;
		border-right: $size solid transparent;
	}
	@else if ($direction == "left"){
		border-top: $size solid transparent;
		border-bottom: $size solid transparent;
		border-right: $size solid $color;
	}
	@else if ($direction == "right"){
		border-top: $size solid transparent;
		border-bottom: $size solid transparent;
		border-left: $size solid $color;
	}
}
// @include triangleRight(10px, 10px, #fcc);
@mixin triangleRight($width, $height, $color) {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: $height/2 0 $height/2 $width;
    border-color: transparent transparent transparent $color;
}
@mixin triangleLeft($width, $height, $color) {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: $height/2 $width $height/2 0;
    border-color: transparent $color transparent transparent;
}
@mixin triangleTop($width, $height, $color) {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 $width/2 $height $width/2;
    border-color: transparent transparent $color transparent;
}
@mixin triangleBottom($width, $height, $color) {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: $height $width/2 0 $width/2;
    border-color: $color transparent transparent transparent;
}
//*
//* Ghost Vertical Align
//*/
@mixin ghostVerticalAlign($pseudoEl: before){
	&:#{$pseudoEl} {
		content: "";
		display: inline-block;
		width: .1px;
		height: 100%;
		vertical-align: middle;
	}
}
%vertical-align {
	@include ghostVerticalAlign();
}

// em пример: p { font-size: em(14); }
$browser-context: 16; // Default

@function em($pixels, $context: $browser-context) {
    @return #{$pixels/$context}em;
}

// bg-url
@mixin bg-url($img-name) {
    background: url('../img/#{ $img-name }');
}
//.elem {
//    @include bg-url('banner-bg.jpg');
//}

// line-height
@function lh($lh, $fz) {
    @return #{decimal-round($lh / $fz, 3)}
}