
/* ========== lifehacks ========== */
.lifehacks {
	padding: 20px 0 75px;
	border-bottom: 5px solid #572d56;
}
.lifehacks__head {
	padding: 40px 0 20px;
	color: #4f254d;
	border-bottom: 1px solid #ede9ed;
}
.lifehacks__title {
	margin: 0 0 30px;
	font-size: 27px;
	font-weight: 700;
	line-height: lh(33, 27);
	text-transform: uppercase;
}
.lifehacks__head-text {
	margin: 0 0 10px;
	font-size: 14px;
	font-weight: 500;
	line-height: lh(20, 14);
}
.lifehack {
	padding: 36px 0 42px;
	border-bottom: 1px solid #ede9ed;
}
.lifehack__head {
	margin-bottom: 18px;
	padding-left: 19px;
	border-left: 3px solid #4f254d;
	@include cf;
	color: #4f254d;
}
.lifehack__title {
	float: left;
	margin: 0;
	font-size: 17px;
	font-weight: 700;
	line-height: 1.335;
	text-transform: uppercase;
}
.lifehack__readmore {
	float: right;
	margin-top: 2px;
	border: 2px solid #4f254d;
	border-radius: 17px;
	text-decoration: none;
	font-size: 14px;
	font-weight: 600;
	line-height: 1;
	padding: 10px 12px;
	transition: color .15s, background-color .15s;
	&:focus,
	&:hover {
		color: #fff;
		background-color: #4f254d;
	}
}
.lifehack__slider {
	font-size: 0;
	.slick-list {
		display: inline-block;
		vertical-align: top;
		width: 67.15%;
	}
	img {
		display: block;
		width: 100%;
	}
	.slick-dots {
		display: inline-block;
		vertical-align: top;
		width: 32.85%;
	}
	.slick-dots li {
		display: inline-block;
		vertical-align: bottom;
		width: 50%;
		padding: 7px 0 0 7px;
		&:nth-child(1),
		&:nth-child(2) {
			padding-top: 0;
		}
	}
	.slick-arrow {
		width: 13px;
		height: 24px;
		margin-top: -12px;
		transition: opacity .15s;
		&:hover {
			opacity: .7;
		}
	}
	.slick-prev {
		left: -29px;
		background: url($img + '/lifehacks/prev.png') no-repeat;
	}
	.slick-next {
		right: -29px;
		background: url($img + '/lifehacks/next.png') no-repeat;
	}
}
.lifehack-slider__link {
	display: block;
}
.lifehack__thumb {
	position: relative;
	display: block;
	width: 100%;
	border: 0;
	padding: 0;
	&:after {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		border: 3px solid transparent;
		transition: border-color .15s, background-color .15s;
	}
}
.lifehack__slider .slick-dots .slick-active .lifehack__thumb,
.lifehack__thumb:hover {
	&:after {
		border-color: #4f254d;
		background-color: rgba(79, 37, 77, .3);
	}
}
.lifehack__slider .slick-dots .slick-active .lifehack__thumb:hover:after {
	cursor: default;
}
.lifehack__thumbs {
	position: absolute;
	overflow: hidden;
	clip: rect(0 0 0 0);
	height: 1px;
	width: 1px;
	margin: -1px;
	padding: 0;
	border: 0;
}
.lg-outer {
	z-index: 1000000000;
}
.lg-backdrop {
	z-index: 999999951;
}
.lifehacks__text {
	padding: 36px 0 16px;
	font-size: 14px;
	font-weight: 400;
	line-height: lh(20, 14);
	color: #adadad;
	ul {
		margin-left: 20px;
		li {
			position: relative;
			padding-left: 20px;
			&:before {
				content: "";
				position: absolute;
				top: 7px;
				left: 0;
				width: 5px;
				height: 5px;
				border-radius: 50%;
				background-color: #adadad;
			}
		}
	}
}
@media (max-width: 1090px) {
	.lifehack__slider {
		padding: 0 22px;
		.slick-prev {
			left: 0;
		}
		.slick-next {
			right: 0;
		}
	}
}
@media (max-width: 640px) {
	.lifehack__slider {
		display: block;
		.slick-list {
			display: block;
			width: auto;
			margin-bottom: 1%;
		}
		.slick-dots {
			display: block;
			width: auto;
			margin: 0 -1%;
			overflow: hidden;
		}
		.slick-dots li {
			display: inline-block;
			width: 31.3%;
			padding: 0;
			margin: 1%;
		}
		.slick-arrow {
			top: 30%;
		}
	}
}
@media (max-width: 420px) {
	.lifehack__slider {
		.slick-dots li {
			display: inline-block;
			width: 47.8%;
		}
		.slick-arrow {
			top: 17%;
		}
	}
}